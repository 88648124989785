import { Dispatch } from 'redux'
import { Action, ActionType } from '../types/keyType'

export const updateKey = (key: string, pricingData?: any) => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.UPDATE_KEY,
      payload: key,
      pricingData
    })
  }
}

export const resetKey = () => {
  return async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.RESET
    })
  }
}
